<!--
* 北谷信息科技（广州）有限公司拥有本软件所有版权 © 2022，并保留所有权利
* Copyright © 2022, North Valley Information Technology (Guangzhou) Company
* Limited, All Rights Reserved.
-->
<template>
  <div class="device-inspection-config">
    <!-- 查询条件 -->
    <div class="filter-container">
      <el-card shadow="never">
        <el-form class="search-form" size="small" v-show="showSearch" ref="queryForm" label-width="100px"
          :model="queryParams" :inline="true">
          <el-form-item :label="$t('deviceInspectionConfig.label.id')" prop="roleName">
            <el-input v-model="queryParams.id" :placeholder="$t('common.inputPlaceholder')" clearable />
          </el-form-item>
          <el-form-item :label="$t('deviceInspectionConfig.label.adminUnitId')">
            <el-select v-model="queryParams.adminUnitId" :placeholder="$t('common.selectPlaceholder')" clearable
              filterable style="width: 100%;">
              <el-option v-for="(item, index) in adminUnitOptions" :key="index" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('deviceInspectionConfig.label.adminSystemId')">
            <el-select v-model="queryParams.adminSystemId" :placeholder="$t('common.selectPlaceholder')" clearable
              filterable style="width: 100%;">
              <el-option v-for="(item, index) in adminSystemOptions" :key="index" :label="item.label"
                :value="item.value" />
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('deviceInspectionConfig.label.iotModelId')">
            <el-select v-model="queryParams.iotModelId" :placeholder="$t('common.selectPlaceholder')" clearable filterable
              style="width: 100%;">
              <el-option v-for="(item, index) in iotModelOptions" :key="index" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="handleSearch">
              {{ $t('operate.search') }}</el-button>
            <el-button icon="el-icon-refresh" @click="resetSearch">{{ $t('operate.reset') }}</el-button>
          </el-form-item>
        </el-form>
      </el-card>
    </div>
    <!-- 操作符 -->
    <el-card shadow="never">
      <el-row type="flex" justify="space-between" class="mb10">
        <el-col :span="24">
          <el-button type="primary" plain icon="el-icon-plus" size="mini" @click="handleAdd">
            {{ $t('operate.register') }}
          </el-button>
          <el-button type="success" plain icon="el-icon-edit" size="mini" @click="edit()">
            {{ $t('operate.edit') }}
          </el-button>
          <el-button type="danger" plain icon="el-icon-delete" size="mini" @click="handleDelete">
            {{ $t('operate.delete') }}
          </el-button>
          <el-button type="warning" plain icon="el-icon-download" size="mini" :loading="exportLoading"
            @click="handleExport">{{ $t('operate.export') }}</el-button>
        </el-col>
      </el-row>
      <!--  表格数据  -->
      <el-table v-loading="loading" border :data="tableData" style="width: 100% ;"
        @selection-change="handleSelectionChange">
        <!--   选框   -->
        <el-table-column type="selection" width="45" align="center" fixed="left" />
        <el-table-column type="index" label="#" width="45" align="center">
          <template slot-scope="scope">
            {{ (queryParams.pageIndex - 1) * queryParams.pageSize + scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column prop="id" :label="$t('deviceInspectionConfig.label.id')" :show-overflow-tooltip="true"
          min-width="100" align="center" />
        <el-table-column prop="adminUnitName" :label="$t('deviceInspectionConfig.label.adminUnitId')" min-width="100"
          :show-overflow-tooltip="true" align="center" />
        <el-table-column prop="adminSystemName" :label="$t('deviceInspectionConfig.label.adminSystemId')" min-width="100"
          :show-overflow-tooltip="true" align="center" />
        <el-table-column prop="iotModelName" :label="$t('deviceInspectionConfig.label.iotModelId')" min-width="100"
          align="center" />
        <el-table-column prop="interval" :label="$t('deviceInspectionConfig.label.interval')" min-width="100"
          :show-overflow-tooltip="true" align="center" />
        <el-table-column prop="remark" :label="$t('deviceInspectionConfig.label.remark')" min-width="100"
          :show-overflow-tooltip="true" align="center" />
        <el-table-column prop="createdTime" :label="$t('deviceInspectionConfig.label.createdTime')" min-width="120"
          :show-overflow-tooltip="true" align="center" />
        <el-table-column :label="$t('operate.operate')" align="center" min-width="100">
          <template slot-scope="scope">
            <el-button type="text" size="small" icon="el-icon-notebook-2" @click="handleDetail(scope.row)">
              {{ $t('operate.look') }}</el-button>
            <el-button type="text" size="small" icon="el-icon-edit" @click="handleUpdate(scope.row)">
              {{ $t('operate.edit') }}</el-button>
            <el-button type="text" size="small" icon="el-icon-delete" @click="deleteonSubmit(scope.row)">
              {{ $t('operate.delete') }}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--分页-->
      <div class="block mt10" style="text-align:center">
        <el-pagination :current-page="listQuery.page" :page-sizes="listQuery.pageSizes" :page-size="20"
          layout="total, sizes, prev, pager, next, jumper" background :total="total" @size-change="handleSizeChange"
          @current-change="handleCurrentChange" />
      </div>
    </el-card>
    <!-- 添加或修改弹窗 -->
    <el-dialog :title="title" :visible.sync="open" :close-on-click-modal="false" class="drawer">
      <el-form ref="form" :model="form" label-width="120px" :rules="rules" size="small">
        <el-row>
          <el-col :xs="24" :sm="12" :lg="12">
            <el-form-item :label="$t('deviceInspectionConfig.label.id')" prop="id">
              <el-input v-model="form.id" :disabled="idDisabled" />
            </el-form-item>
          </el-col>
          <el-col :span="24"></el-col>
          <el-col :xs="24" :sm="24" :lg="12">
            <el-form-item :label="$t('deviceInspectionConfig.label.adminSystemId')" prop="adminSystemId">
              <el-select v-model="form.adminSystemId" :placeholder="$t('common.selectPlaceholder')" clearable filterable
                style="width: 100%">
                <el-option v-for="(item, index) in adminSystemOptions" :key="index" :label="item.label"
                  :value="item.value" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :lg="12">
            <el-form-item :label="$t('deviceInspectionConfig.label.adminUnitId')" prop="adminUnitId">
              <el-select v-model="form.adminUnitId" :placeholder="$t('common.selectPlaceholder')" clearable filterable
                style="width: 100%">
                <el-option v-for="(item, index) in adminUnitOptions" :key="index" :label="item.label"
                  :value="item.value" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :lg="12">
            <el-form-item :label="$t('deviceInspectionConfig.label.iotModelId')">
              <el-select v-model="form.iotModelId" :placeholder="$t('common.selectPlaceholder')" clearable filterable
                style="width: 100%">
                <el-option v-for="(item, index) in iotModelOptions" :key="index" :label="item.label"
                  :value="item.value" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :lg="12">
            <el-form-item :label="$t('deviceInspectionConfig.label.interval')" prop="interval">
              <el-input type="number" v-model="form.interval" />
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :lg="24">
            <el-form-item :label="$t('deviceInspectionConfig.label.remark')">
              <el-input v-model="form.remark" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" size="small" @click="submitForm('form')">{{ $t('operate.define') }}</el-button>
        <el-button size="small" @click="handleCancel">{{ $t('operate.cancel') }}</el-button>
      </div>
    </el-dialog>
    <!-- Log日志弹窗 -->
    <el-dialog :title="drawerData.title" :visible.sync="drawer" :close-on-click-modal="false" class="drawer"
      append-to-body>
      <el-form :data="drawerData" label-width="120px" disabled="disabled">
        <el-row>
          <el-col :xs="24" :sm="24" :lg="12">
            <el-form-item :label="$t('deviceInspectionConfig.label.id')" prop="id">
              <el-input v-model="drawerData.id" :disabled="idDisabled" />
            </el-form-item>
          </el-col>
          <el-col :span="24"></el-col>
          <el-col :xs="24" :sm="24" :lg="12">
            <el-form-item :label="$t('deviceInspectionConfig.label.adminSystemId')" prop="adminSystemId">
              <el-input v-model="drawerData.adminSystemId" />
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :lg="12">
            <el-form-item :label="$t('deviceInspectionConfig.label.adminUnitId')" prop="adminUnitId">
              <el-input v-model="drawerData.adminUnitId" />
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :lg="12">
            <el-form-item :label="$t('deviceInspectionConfig.label.iotModelId')">
              <el-input v-model="drawerData.iotModelId" />
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :lg="12">
            <el-form-item :label="$t('deviceInspectionConfig.label.interval')" prop="interval">
              <el-input v-model="drawerData.interval" />
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :lg="24">
            <el-form-item :label="$t('deviceInspectionConfig.label.remark')">
              <el-input v-model="drawerData.remark" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" size="small" @click="drawer = false">{{ $t('operate.close') }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'DeviceInspectionConfig',
  data() {
    return {
      // 表格数据
      tableData: [],
      // 详情数据 -> 数据表对应所有信息
      drawerData: {
        title: '',
        config: [{ '': '' }]
      },
      dateRange: [],
      // 封装分页数据
      listQuery: {
        // 当前页数
        page: 1,
        // 一页最大的记录数，默认20条
        pageSize: 20,
        // 每页显示条数的选择器
        pageSizes: [20, 50, 100, 200]
      },
      // 总记录数
      total: 0,
      // 搜索框
      input: '',
      // 新增弹框控制
      insertdialogVidible: false,
      // 遮罩层
      loading: true,
      // 查询参数
      queryParams: {
        pageIndex: 1,
        pageSize: 20,
        id: undefined,
        adminUnitId: undefined,
        adminSystemId: undefined,
        iotModelId: undefined
      },
      // 下拉框数据
      options: [],
      adminUnitOptions: [],
      adminSystemOptions: [],
      iotModelOptions: [],
      // 显示搜索条件
      showSearch: true,
      // 导出遮罩层
      exportLoading: false,
      // 抽屉控制
      drawer: false,
      title: '',
      // 编辑 新增表单数据
      form: {
        type: null, // 用于区别表单是新增还是修改
        id: null, // 服务器ID
        tenantId: null,
        adminUnitId: null,
        adminSystemId: null,
        iotModelId: null,
        content: null,
        interval: null,
        remark: null, // 备注
        createdTime: null, // 注册时间
        createdUserId: null, // 注册用户Id
        updatedTime: null, // 更新时间
        updatedUserId: null, // 更新用户Id
      },
      // 控制表单弹框
      open: false,
      // 表单规则
      rules: {
        id: [
          { required: true, message: this.$t('deviceInspectionConfig.rules.message.id'), trigger: 'blur' }
        ]
      },
      // 控制label
      labelPosition: 'left',
      // 控制ID的disabled
      idDisabled: false,
      // 记录选中行信息
      selectVal: [],
      config: [['', '']]
    }
  },
  created() {
    // 获取请求参数，修改字段语言
    this.changeLocale()
    // 获取列表数据
    this.getList()
    this.initOption()
  },
  methods: {
    initOption() {
      this.getAdminUnitList();
      this.getAdminSystemList();
      this.getIotModelList();
    },
    getAdminUnitList() {
      this.$api.getOrganizationUnitList({ pageIndex: 1, pageSize: 1000 }).then(res => {
        const list = res.data?.list;
        list.forEach(unit => {
          const obj = {
            label: unit.name,
            value: unit.id
          }
          this.adminUnitOptions.push(obj);
        })
      })
    },
    getAdminSystemList() {
      this.$api.getDeviceAdminSystemList({ pageIndex: 1, pageSize: 1000 }).then(res => {
        const list = res.data?.list;
        list.forEach(unit => {
          const obj = {
            label: unit.name,
            value: unit.id
          }
          this.adminSystemOptions.push(obj);
        })
      })
    },
    getIotModelList() {
      this.$api.getIotModelList({ pageIndex: 1, pageSize: 1000 }).then(res => {
        const list = res.data?.list;
        list.forEach(unit => {
          const obj = {
            label: unit.name,
            value: unit.id
          }
          this.iotModelOptions.push(obj);
        })
      })
    },
    /**
    * @methods getList
    * @description 获取列表数据
    * @param 
    */
    getList() {
      this.loading = true
      // 发送请求数据
      this.$api.getDeviceInspectionConfigList(this.queryParams).then(res => {
        // 拿到结果先判断机器类型
        res.data.list.forEach(item => {
          item.createdTime = this.changeTime(item.createdTime)
          item.updatedTime = this.changeTime(item.updatedTime)
        })
        this.tableData = res.data.list
        this.total = res.data.total
      })
      // 取消遮罩层
      this.loading = false
    },
    /**
    * @methods reset
    * @description 表单重置
    */
    reset() {
      this.form = {
        type: null, // 用于区别表单是新增还是修改
        id: null, // 服务器ID
        tenantId: null,
        adminUnitId: null,
        adminSystemId: null,
        iotModelId: null,
        content: null,
        interval: null,
        remark: null, // 备注
        createdTime: null, // 注册时间
        createdUserId: null, // 注册用户Id
        updatedTime: null, // 更新时间
        updatedUserId: null, // 更新用户Id
      }
    },
    /**
    * @methods resetQuery
    * @description 重置查询条件
    */
    resetQuery() {
      this.queryParams = {
        pageIndex: 1,
        pageSize: 20,
        id: undefined,
        adminUnitId: undefined,
        adminSystemId: undefined,
        iotModelId: undefined
      }
    },
    /**
    * @methods resetSearch
    * @description 重置搜索条件
    */
    resetSearch() {
      this.resetQuery()
      this.getList()
    },
    /**
    * @methods changeLocale
    * @description 修改语言
    */
    changeLocale() {
      const language = this.$route.query.language
      if (language === 'en') {
        this.$i18n.locale = 'en_US'
        this.$store.commit('changeLanguage')
      } else {
        this.$i18n.locale = 'zh_CN'
      }
    },
    /**
    * @methods handleSizeChange
    * @description 点击修改每页最大数触发
    * @param {Object} params val 表示一页需要多少条数，pageSize
    */
    handleSizeChange(val) {
      // val 表示一页需要多少条数，pageSize
      this.queryParams.pageSize = `${val}`
      this.getList()
    },
    /**
    * @methods handleCurrentChange
    * @description 点击切换页数触发
    * @param {Object} params val 表示当前第几页,pageIndex
    */
    handleCurrentChange(val) {
      // val 表示当前第几页,pageIndex
      this.queryParams.pageIndex = `${val}`
      this.getList()
    },
    /**
    * @methods handleSelectionChange
    * @description 使用选框时触发
    * @param {Object} params val 表示选中的信息
    */
    handleSelectionChange(val) {
      this.selectVal = val
    },
    /**
    * @methods handleUpdate
    * @description 编辑处理
    * @param {Object} params row 表示某一行数据
    */
    handleUpdate(row) {
      this.open = true
      this.reset()
      // 深拷贝
      this.form = Object.assign({}, row)
      this.form.type = 'edit'
      this.idDisabled = true
      this.title = this.$t('deviceInspectionConfig.title.edit')
    },
    /**
    * @methods edit
    * @description 点击表格上方的编辑按钮触发
    */
    edit() {
      if (this.selectVal.length !== 1) {
        this.$message({
          type: 'error',
          message: this.$t('message.edit.tips')
        })
      } else {
        this.handleUpdate(this.selectVal[0])
      }
    },
    /**
    * @methods handleAdd
    * @description 点击新增按钮触发
    */
    handleAdd() {
      this.idDisabled = false
      this.open = true
      this.reset()
      this.form.type = 'add'
      this.title = this.$t('deviceInspectionConfig.title.register')
    },
    /**
    * @methods submitForm
    * @description 编辑和新增的表单确认按钮
    * @param {Object} params formName 表单名字
    */
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.form.type === 'add') {
            // 发送请求新增服务器信息
            this.form.time = this.newTime()
            this.$api.addDeviceInspectionConfig(this.form).then(res => {
              this.showTipes(res.code, this.$t('message.add.success'), this.$t('message.add.fail'))
            })
          } else if (this.form.type === 'edit') {
            this.form.time = this.newTime()
            // 发送请求修改数据
            this.$api.updateDeviceInspectionConfig(this.form).then(res => {
              this.showTipes(res.code, this.$t('message.edit.success'), this.$t('message.edit.fail'))
            })
          }
          // 发送请求编辑或新增
          // 关闭表单
          this.open = false
          // 重置表单
          this.reset()
        } else {
          return false
        }
      })
    },
    /**
    * @methods handleCancel
    * @description 编辑和注册的取消按钮
    */
    handleCancel() {
      // 关闭表单
      this.open = false
      // 重置表单
      this.reset()
    },
    /**
    * @methods deleteonSubmit
    * @description 删除按钮
    * @param {Object} params row 当前行
    */
    deleteonSubmit(row) {
      this.$confirm(this.$t('message.delete.message'), this.$t('title'), {
        confirmButtonText: this.$t('operate.define'),
        cancelButtonText: this.$t('operate.cancel'),
        type: 'warning'
      }).then(() => {
        // 发送请求删除当前行数据，重新getList()
        this.$api.deleteDeviceInspectionConfig(row.id).then((res) => {
          this.showTipes(res.code, this.$t('message.delete.success'), this.$t('message.delete.fail'))
        })
      })
    },
    /**
    * @methods handleDelete
    * @description 用户按下删除按钮时触发
    */
    handleDelete() {
      if (!this.selectVal || this.selectVal.length === 0) {
        this.$message({
          type: 'error',
          message: this.$t('message.delete.tips')
        })
        return
      }
      let arrVal = []
      this.selectVal.forEach(item => {
        arrVal.push(item.id)
      });
      const strVal = arrVal.join(',');

      this.$confirm(this.$t('message.delete.message'), this.$t('title'), {
        confirmButtonText: this.$t('operate.define'),
        cancelButtonText: this.$t('operate.cancel'),
        type: 'warning'
      }).then(() => {
        this.$api.deleteDeviceInspectionConfig(strVal).then(res => {
          this.showTipes(res.code, this.$t('message.delete.success'), this.$t('message.delete.fail'))
        })
      })
    },
    /**
    * @methods handleSearch
    * @description 搜索按钮
    */
    handleSearch() {
      this.queryParams.pageIndex = 1
      this.getList()
    },
    /**
    * @methods handleDetail
    * @description 查看详情按钮
    * @param {Object} params row 当前行
    */
    handleDetail(row) {
      this.drawer = true
      this.drawerData = row
      this.drawerData.title = this.$t('deviceInspectionConfig.title.Info')
    },
    /**
    * @methods handleExport
    * @description 导出按钮
    */
    handleExport() {
      this.$api.exportDeviceInspectionConfigsAsExcelFile(this.queryParams).then(res => {
        this.exportFile(this.$t('export.deviceInspectionConfig'), res)
      })
    },
    /**
    * @methods showTipes
    * @description 弹框提示信息
    * @param {Object} params 错误码、提示信息对应的i18n字段
    */
    showTipes(code, sucessMessage, errorMessage) {
      if (code === 1) {
        this.resetQuery()
        this.getList()
        this.$message({
          type: 'success',
          message: sucessMessage
        })
      } else {
        this.$message({
          type: 'error',
          message: errorMessage
        })
      }
    }
  }
}
</script>

<style scoped>
::v-deep .el-dialog__header {
  padding: 0.625rem 0.625rem 0.25rem;
  background-color: #f8f8f8;
  border-bottom: 0.0625rem solid #eee;
  overflow: hidden;
}

::v-deep .el-dialog__body {
  padding: 1.875rem 1.25rem;
}

::v-deep .el-dialog__footer {
  border-top: 1px solid #eee;
}

::v-deep .el-dialog__headerbtn {
  top: 0.9375rem;
}
</style>
